import React from "react"
import { Helmet } from "react-helmet"
import fetch from "isomorphic-fetch"
import { polyfill } from "es6-promise"

import "../layout.css"

polyfill()

function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}

function dataCleanse(restaurants) {
  restaurants = restaurants.filter(function (r) {
    if (
      ((r.location.map_data &&
        r.location.map_data.components &&
        r.location.map_data.components.region) ||
        (r.location.map_data &&
          r.location.map_data.components &&
          r.location.map_data.components.administrative_area_level_1 &&
          r.location.map_data.components.administrative_area_level_1 ===
          "NT")) &&
      (r.services.pickup.enabled === true ||
        r.services.delivery.enabled === true ||
        r.services.table_booking.enabled === true)
    ) {
      return true
    } else {
      return false
    }
  })
  return restaurants.map(function (r) {
    var image = ""
    try {
      if (r.website.sections.header.images.background.not_uc) {
        image = r.website.sections.header.images.background._id
      } else {
        image = `https://ucarecdn.com/${r.website.sections.header.images.background._id}/-/format/auto/-/progressive/yes/${r.website.sections.header.images.background.name}`
      }
    } catch {
      console.log("No image for ", r.alt_store_name)
    }
    return {
      name: r.alt_store_name || "",
      hours: r.location.opening_hours || [],
      phone: r.location.phone || "",
      address: r.location.address || "",
      url: r.store_url || "",
      image: image || "",
      // dinein: r.services.dine_in.enabled,
      pickup: r.services.pickup.enabled,
      delivery: r.services.delivery.enabled,
      table: r.services.table_booking.enabled,
      open: r.is_open || false,
    }
  })
}

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stores: [],
      tempStores: [],
      loading: true,
      selectedStore: "",
      selectedHours: [],
      modal: false,
      modalRegister: false,
      restaurantName: "",
      fullName: "",
      email: "",
      phone: "",
    }
  }
  componentDidMount() {
    const apiURL = "https://api.cloudwaitress.com/resellers/portal/newworldcommunications-data"
    // const apiURL = "http://localhost:3010/resellers/portal/newworldcommunications-data/"

    fetch(apiURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        reseller_id: "newworldcommunications",
      }),
    })
      .then(response => {
        return response.json()
      })
      .then(data => {
        let stores = this.state.tempStores
        data = dataCleanse(data)
        data.map(store => {
          stores.push(store)
          return store
        })
        shuffle(stores)

        this.setState({
          stores: stores,
          loading: false,
        })
      })
  }
  openModal = (storeName, storeHours) => {
    this.setState(
      {
        modal: true,
        selectedStore: storeName,
        selectedHours: storeHours,
      },
      () => {
        const body = document.querySelector("body")
        body.classList.add("modal-active")
      }
    )
  }
  openModalRegister = () => {
    this.setState({
      modalRegister: true,
    })
  }
  closeModal = () => {
    this.setState(
      {
        modal: false,
        selectedStore: "",
        selectedHours: [],
      },
      () => {
        const body = document.querySelector("body")
        body.classList.remove("modal-active")
      }
    )
  }
  closeModalRegister = () => {
    this.setState({
      modalRegister: false,
    })
  }
  render() {
    return (
      <div className="main-container">
        <header className="header flex flex-row flex-wrap justify-center items-start w-full">
          <div className="header-container flex flex-row flex-wrap mt-8 justify-between items-center container">
            <a href="/" className="logo-wrapper">
              <img
                className="logo"
                src="/images/logo.png"
                alt="logo"
                style={{ height: "70px" }}
              />
            </a>

            {/* <a
              target="_blank"
              href="https://get.deliverish.com.au/"
              className="venues-button"
            >
              For venues
            </a> */}
          </div>
          <div className="header-container container w-full bg-clip-text">
            <h1 className="text-5xl font-bold text-center my-6 py-2 tracking-wide leading-none clip-text text-gradient-yellow-to-white">
              Your Restaurant & Your Brand Our Solution
            </h1>
          </div>
        </header>
        <main role="main" className="flex flex-row justify-center w-full">
          <div className="flex flex-grow"></div>
          <div
            className={`content-container container flex flex-col min-h-screen justify-center ${this.state.loading ? "loading" : "loaded"
              }`}
          >
            <Helmet>
              <title>
                Live Ordering 360 – Powered by Next World Communications
              </title>
              {/* <script
                async
                src="https://www.googletagmanager.com/gtag/js?id=UA-163843822-1"
              ></script> */}
              {/* <script
                dangerouslySetInnerHTML={{
                  __html: `window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('js', new Date());
                            gtag('config', 'UA-163843822-1');`,
                }}
              /> */}
              <link rel="icon" type="image/jpeg" href="/favicon.jpg" />
              {/* <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css?family=Baloo+Tamma+2"
              /> */}
              <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css?family=Poppins"
              />
            </Helmet>

            {this.state.loading ? (
              <div className="flex flex-grow w-full justify-center pt-8">
                <div className="lds-facebook">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            ) : (
                <div>
                  <div className="flex flex-wrap mx-2 justify-center">
                    <div className="flex flex-wrap justify-center w-full md:w-1/2 lg:w-full xl:w-full card-wrapper">
                      {this.state.stores.map((r, i) => {
                        return (
                          <RestaurantCard
                            key={i}
                            restaurant={r}
                            openModal={this.openModal}
                          />
                        )
                      })}
                    </div>
                  </div>
                </div>
              )}
            {/* <div className="flex w-full justify-center pt-24 pb-12">
              <button
                className="bg-transparent hover:bg-orange-500 text-orange-500 font-semibold hover:text-white py-4 px-8 border border-orange-500 hover:border-transparent rounded"
                onClick={this.openModalRegister}
                onKeyDown={this.openModalRegister}
              >
                Restaurant Partners
              </button>
            </div> */}
            <div className="pt-8  text-center footer">
              <hr />
              <hr />
              <div className="pt-4 pb-1">
                <p className="text-center text-base font-bold mb-2">
                  &copy; {new Date().getFullYear()} - Live Ordering 360
                </p>
              </div>
            </div>
            <div
              className={`"modal ${this.state.modal === true ? "" : "opacity-0 pointer-events-none"
                } fixed w-full h-full top-0 left-0 flex items-center justify-center"`}
            >
              <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
              <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
                <div className="modal-content p-4">
                  <div className="flex justify-center items-center pb-6">
                    <p className="text-2xl font-bold poppins">
                      {this.state.selectedStore} - Hours
                    </p>
                  </div>
                  <div className="flex w-full justify-center">
                    <div className="flex flex-col w-full">
                      {this.state.selectedHours.map((hours, index) => {
                        var diff = false
                        if (index === 0) {
                          diff = true
                        } else {
                          diff =
                            this.state.selectedHours[index].day ===
                              this.state.selectedHours[index - 1].day
                              ? false
                              : true
                        }
                        return (
                          <div key={index} className="w-full">
                            {diff ? (
                              <div className="py-1">
                                <hr className="text-gray-400" />
                                <hr className="text-gray-400" />
                              </div>
                            ) : (
                                <div></div>
                              )}
                            <div
                              className={`flex w-full custom-grey text-sm md:text-lg ${diff ? "py-1" : "py-0"
                                }`}
                            >
                              {diff ? (
                                <span className="poppins w-1/2 text-left brand-foreground">
                                  {hours.day}:
                                </span>
                              ) : (
                                  <span className="w-1/2"></span>
                                )}
                              <div className="w-1/2 text-right">
                                {hours.open} - {hours.close}
                              </div>
                            </div>
                          </div>
                        )
                      })}
                      <div className="pt-1">
                        <hr className="text-gray-400" />
                        <hr className="text-gray-400" />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center pt-8">
                    <button
                      className="bg-transparent hover:bg-brand-primary text-brand-primary font-semibold hover:text-white py-1 px-6 border border-brand-primary hover:border-transparent rounded-full"
                      onClick={this.closeModal}
                      onKeyDown={this.closeModal}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`"modal ${this.state.modalRegister === true
                ? ""
                : "opacity-0 pointer-events-none"
                } fixed w-full h-full top-0 left-0 flex items-center justify-center"`}
            >
              <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
              <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
                <div className="modal-content pt-6 pb-0 px-6">
                  <div className="flex justify-center items-center pb-6">
                    <p className="text-4xl font-bold poppins brand-foreground">
                      Join Deliverish
                    </p>
                  </div>
                  <div className="flex flex-col w-full justify-center text-center">
                    <p>
                      Get your restaurant started with commission-free online
                      ordering using the form below.
                    </p>
                    <p>We will contact your to discuss and get you started.</p>
                  </div>
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="username"
                    >
                      Username
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="username"
                      type="text"
                      placeholder="Username"
                    />
                  </div>
                  <div className="mb-6">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                      id="password"
                      type="password"
                      placeholder="Password"
                    />
                    <p className="text-red-500 text-xs italic">
                      Please choose a password.
                    </p>
                  </div>
                  <div className="flex items-center justify-between">
                    <button
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      type="button"
                    >
                      Register
                    </button>
                  </div>
                  <div className="flex justify-center py-8">
                    <button
                      className="bg-transparent hover:bg-orange-500 text-orange-500 font-semibold hover:text-white py-4 px-8 border border-orange-500 hover:border-transparent rounded"
                      onClick={this.closeModalRegister}
                      onKeyDown={this.closeModalRegister}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-grow"></div>
        </main>
      </div>
    )
  }
}

// const IconBarLong = props => {
//   return (
//     <div className="flex rounded-lg px-6 bg-white justify-between py-2">
//       <div className="flex flex-row align-middle justify-center">
//         <div className="flex w-full justify-center">
//           <img
//             className="flex h-16 self-center"
//             src="/icons/01.svg"
//             alt="Location"
//           />
//         </div>
//         <div className="flex text-4xl font-bold baloo text-orange-600 self-center pt-2">
//           01
//         </div>
//         <div className="flex flex-col baloo self-center pt-1 align-middle text-2xl md:text-base text-center pl-4">
//           <div className="-m-1">Choose</div>
//           <div className="-m-1">location</div>
//         </div>
//         <div className="hidden md:flex flex-col baloo self-center pt-1 align-middle px-12 text-gray-500">
//           >
//         </div>
//       </div>
//       <div className="flex flex-row align-middle">
//         <img className="flex h-16 self-center" src="/icons/02.svg" alt="Food" />
//         <div className="flex text-4xl font-bold baloo text-orange-600 self-center pt-2">
//           02
//         </div>
//         <div className="flex flex-col baloo self-center pt-1 align-middle text-2xl md:text-base text-center pl-4">
//           <div className="-m-1">Order</div>
//           <div className="-m-1">food</div>
//         </div>
//         <div className="hidden md:flex flex-col baloo self-center pt-1 align-middle px-12 text-gray-500">
//           >
//         </div>
//       </div>
//       <div className="flex flex-row align-middle justify-center">
//         <img
//           className="flex h-16 self-center -mt-0"
//           src="/icons/03.svg"
//           alt="Delivery/Takeout"
//         />
//         <div className="flex text-4xl font-bold baloo text-orange-600 self-center pt-2 pl-1">
//           03
//         </div>
//         <div className="flex flex-col baloo self-center pt-1 align-middle text-base text-center pl-4">
//           <div className="-m-1">Delivery or</div>
//           <div className="-m-1">Take Out</div>
//         </div>
//       </div>
//     </div>
//   )
// }

// const IconBarThin = props => {
//   return (
//     <div className="flex flex-col w-full justify-center px-12 sm:px-32 md:px-64">
//       <div className="flex justify-between">
//         <img
//           className="flex h-16 md:h-16 self-center"
//           src="/icons/01.svg"
//           alt="Location"
//         />
//         <img
//           className="flex h-16 md:h-16 self-center"
//           src="/icons/02.svg"
//           alt="Order"
//         />
//         <img
//           className="flex h-16 md:h-16 self-center"
//           src="/icons/03.svg"
//           alt="Delivery/Pickup"
//         />
//       </div>
//       <div className="flex justify-between px-2">
//         <div className="flex text-5xl md:text-4xl font-bold baloo text-orange-600 self-center pt-0 md:pt-2 justify-center">
//           01
//         </div>
//         <div className="flex baloo self-center align-middle text-gray-500 text-4xl pb-1">
//           >
//         </div>
//         <div className="flex text-5xl md:text-4xl font-bold baloo text-orange-600 self-center pt-0 md:pt-2 pl-2 justify-center">
//           02
//         </div>
//         <div className="flex baloo self-center align-middle text-gray-500 text-4xl pb-1">
//           >
//         </div>
//         <div className="flex text-5xl md:text-4xl font-bold baloo text-orange-600 self-center pt-0 md:pt-2 justify-center">
//           03
//         </div>
//       </div>
//       <div className="flex justify-between pt-0 -mt-2 -mx-4">
//         <div className="flex flex-col baloo self-center pt-0 md:pt-1 align-middle text-2xl md:text-base text-center pl-2 md:ml-2">
//           <div className="-m-1">Choose</div>
//           <div className="-m-1">location</div>
//         </div>
//         <div className="flex flex-col baloo self-center pt-0 md:pt-1 align-middle text-2xl md:text-base text-center pl-6 md:pl-4">
//           <div className="-m-1">Order</div>
//           <div className="-m-1">food</div>
//         </div>
//         <div className="flex flex-col baloo self-center pt-0 md:pt-1 align-middle text-2xl md:text-base text-center mr-0">
//           <div className="-m-1">Delivery or</div>
//           <div className="-m-1">Take out</div>
//         </div>
//       </div>
//     </div>
//   )
// }
function removeStatePostcode(string) {
  var statePosition = string.indexOf("Northern Territory")
  var statePostcode = string.substr(statePosition)
  string = string.replace(statePostcode, "")

  var postCodePosition = string.indexOf("NT 08")
  if (postCodePosition > 0) {
    var postcode = string.substr(postCodePosition)
    string = string.replace(postcode, "")
  }

  return string
}
const RestaurantCard = props => {
  var pickAndDdelivery = props.restaurant.pickup && props.restaurant.delivery
  var trimedAddress = removeStatePostcode(props.restaurant.address)

  return (
    <div className="w-90 m-4 pb-6 card-item">
      <div className="max-w-sm rounded ">
        <a href={props.restaurant.url}>
          <div
            className="flex w-full image-scale-wrapper bg-cover bg-no-repeat bg-center bg-gray-100"
            style={{
              height: "200px",
              backgroundImage: `url(${props.restaurant.image})`,
            }}
          />
        </a>
        <div className="card-container ">
          <div className="font-bold text-3xl my-3 tracking-tight leading-none">
            <h2>{props.restaurant.name}</h2>
          </div>
          <div className="text-gray-700 text-base mt-0 px-1 w-full ">
            {pickAndDdelivery ? (
              <div key="pickupDelivery" className={`rounded-lg category `}>
                {" "}
                Pickup & Delivery
              </div>
            ) : (
                [
                  props.restaurant.pickup ? (
                    <div key="pickup" className={`rounded-lg category `}>
                      Pickup only
                    </div>
                  ) : (
                      ""
                    ),
                  props.restaurant.delivery ? (
                    <div key="delivery" className={`rounded-lg category `}>
                      Delivery only
                    </div>
                  ) : (
                      ""
                    ),
                ]
              )}

            {props.restaurant.table && (
              <div className={`category rounded-lg`}>Bookings</div>
            )}
          </div>
          <div className="flex text-base flex-row lg:flex-no-wrap flex-wrap mt-2 ">
            <div className="text-base flex-1 flex items-center">
              <span className="mr-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16.659px"
                  height="23px"
                  viewBox="0 0 16.659 23"
                >
                  <path
                    style={{ fill: "#8f9498" }}
                    d="M8.33,0C3.737,0,0,3.737,0,8.33c0,5.7,7.454,14.068,7.772,14.422
        c0.298,0.332,0.819,0.331,1.116,0c0.317-0.353,7.772-8.721,7.772-14.422C16.659,3.737,12.923,0,8.33,0 M8.33,12.521
        c-2.311,0-4.191-1.88-4.191-4.191s1.88-4.191,4.191-4.191s4.191,1.88,4.191,4.191S10.641,12.521,8.33,12.521"
                  />
                </svg>
              </span>
              <a
                className="location-text mr-3"
                target="_blank"
                href={`http://maps.google.com/maps?q=${props.restaurant.address.replace(
                  /\s+/g,
                  "+"
                )}`}
              >
                {trimedAddress}
              </a>
            </div>

            <div className="flex text-gray-700 text-base flex-1 brand-foreground items-center">
              <span className="mr-4 -mt-1 ml-auto">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8 14.6666C11.6819 14.6666 14.6667 11.6819 14.6667 7.99998C14.6667 4.31808 11.6819 1.33331 8 1.33331C4.3181 1.33331 1.33333 4.31808 1.33333 7.99998C1.33333 11.6819 4.3181 14.6666 8 14.6666ZM8.89999 4.33331C8.89999 3.83626 8.49705 3.43331 7.99999 3.43331C7.50294 3.43331 7.09999 3.83626 7.09999 4.33331V7.99998C7.09999 8.23867 7.19482 8.46759 7.3636 8.63638L9.3636 10.6364C9.71507 10.9878 10.2849 10.9878 10.6364 10.6364C10.9879 10.2849 10.9879 9.71506 10.6364 9.36358L8.89999 7.62719V4.33331Z"
                    fill={props.restaurant.open ? "#5d8d91" : "#8f9498"}
                  />
                </svg>
              </span>
              <div
                className={`mr-1 opending-hour ${props.restaurant.open ? "open" : "closed"
                  }`}
              >
                <div>{props.restaurant.open ? "Open now" : "Closed"}</div>

                <div
                  className={` underline cursor-pointer `}
                  onClick={e =>
                    props.openModal(
                      props.restaurant.name,
                      props.restaurant.hours
                    )
                  }
                  onKeyDown={e =>
                    props.openModal(
                      props.restaurant.name,
                      props.restaurant.hours
                    )
                  }
                  role="button"
                  tabIndex={0}
                >
                  View hours
                </div>
              </div>
            </div>
          </div>
          <a
            href={props.restaurant.url}
            className="order-button flex brand-background cursor-pointer text-white justify-center font-bold"
          >
            View Menu & Order
          </a>
        </div>
      </div>
    </div>
  )
}

export default IndexPage
